import React from "react"

import { graphql } from "gatsby"

import { SEO } from "@src/components"

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Not Found" />
      <h1>Not Found</h1>
      <p>This page is not available &colon;&lpar;</p>
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
